import { Box, Typography } from '@mui/material';

function Header() {
  return (
    <Box>
      <Typography align="center" variant="h4">
        VcanFT PanPay Tooling
      </Typography>
    </Box>
  );
}

export default Header;
