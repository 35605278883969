import { Box, Container } from '@mui/material'
import Header from '../Header'
import App from '../App'

import './style.css'

function Layout() {
  return(
    <Container className="App" maxWidth="lg">
      <Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }} >
        <Header />
        <Box>
          <App />
        </Box>
      </Box>
    </Container>
  )
}

export default Layout