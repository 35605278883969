const BASE_URL = "https://test.panpay.com/gateway/oauth"
const AUTH_ROUTE = "authorize.do"
const TOKEN_ROUTE = "token.do"

const APP_KEY_KEY = "appKey"
const APP_Key = "b118bc1d-95bb-4002-b692-97841e9782c1"

const APP_SECRET_KEY = "appSecret"
const APP_SECRET = "5c473ae5-a23d-4bd5-bec8-54feb4fa9d03"

const REDIRECT_URI_KEY = "redirectUri"
const REDIRECT_URI = "https://panpay.vcanft.com"

const THIRD_CHANNEL_ID_KEY = "thirdChannelId"
const THIRD_CHANNEL_ID = "vcanumbrella"

const SCOPE_KEY = "scope"
const SCOPE = "general"

const STATE_KEY = "state"
const STATE = 22

const CODE_KEY = "code"

const GRANT_TYPE_KEY = "grantType"
const GRANT_TYPE = "authorization_code"

const initAuthForPanPay = () => {
  const fetchUrl = BASE_URL + '/' + AUTH_ROUTE + '?'
                  + APP_KEY_KEY + '=' + APP_Key + '&'
                  + APP_SECRET_KEY + '=' + APP_SECRET + '&'
                  + REDIRECT_URI_KEY + '=' + REDIRECT_URI + '&'
                  + THIRD_CHANNEL_ID_KEY + '=' + THIRD_CHANNEL_ID + '&'
                  + SCOPE_KEY + '=' + SCOPE + '&'
                  + STATE_KEY + '=' + STATE

  window.open(fetchUrl, "_self")
}

const fetchToken = (tokenCode, callback) => {
  const fetchUrl = BASE_URL + '/' + TOKEN_ROUTE + '?'
                  + GRANT_TYPE_KEY + '=' + GRANT_TYPE + '&'
                  + APP_KEY_KEY + '=' + APP_Key + '&'
                  + APP_SECRET_KEY + '=' + APP_SECRET + '&'
                  + REDIRECT_URI_KEY + '=' + REDIRECT_URI + '&'
                  + CODE_KEY + '=' + tokenCode
  fetch(fetchUrl)
    .then(res => res.json())
    .then((data)=>callback(data));
}

export {
  initAuthForPanPay,
  fetchToken
}