import { useState } from 'react'
import { Button, Typography } from '@mui/material'

import { initAuthForPanPay, fetchToken } from '../../api'

let token = {}

function App() {
  /* State Descriptions: 
    -1 - State to be determined
    0 - Initial State: Should get authorized with PanPay
    1 - Authed /wo Token: Authed, should get Token from the Redirect URL
  */
  const S_UNDETERMINED = -1  //page load, state to be determined
  const S_INIT = 0     //Should start the authorization process with PanPay
  const S_FETCHTOKEN = 10
  const S_GOTTOKEN = 11

  const [fsm, setFsm] = useState(S_UNDETERMINED);

  const urlParams = new URLSearchParams(window.location.search)

  const onSumbitHandler = () => {
    initAuthForPanPay()
  }

  switch (fsm) {
    case S_UNDETERMINED:
      if (urlParams.entries().next().done)
        setFsm(S_INIT)
      else if (urlParams.has('code'))
        setFsm(S_FETCHTOKEN)
      return (
        <>
          <Typography>Page Loading...</Typography>
        </>
      )
    case S_FETCHTOKEN:
      const tokenCode = urlParams.get('code')
      fetchToken(tokenCode, (resToken)=>{
        console.log(resToken)
        token = resToken
        setFsm(S_GOTTOKEN)
      })
      return (
        <>
          <Typography>Fetching Token...</Typography>
        </>
      )
    case S_GOTTOKEN:
      return (
        <>
          <Typography>{JSON.stringify(token)}</Typography>
        </>
      )
    case S_INIT:
      return (
        <>
          <Button variant="text" onClick={onSumbitHandler}>Start</Button>
        </>
      )
    default:
      return (
        <>
          <Typography>Unknown Page Error</Typography>
        </>
      )

  }
}

export default App;
